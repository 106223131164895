import styled from "styled-components";
import { altTextBlack, teal } from "../../../styles/shared/colors";

export const Root = styled.div`
    text-align: center;
    color: ${altTextBlack};
    font-size: 12px;
    line-height: 1.25;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 16px;
    margin-bottom: 16px;
  

    sup {
        color: ${teal};
    }


`;