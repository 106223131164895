import React from 'react';
import { WrapperEl } from '../../../styles/shared/layout';
import * as s from './styles';


const PageSection = ({
  grey = false,
  narrow = false,
  purple = false,
  children,
  className,
}) => {

  return (
    <s.PageSectionEl usePurple={purple} useGrey={grey} className={className}>
      <WrapperEl className='pageSectionWrapper' useNarrow={narrow}>

        {children}

      </WrapperEl>
    </s.PageSectionEl>
  );
};


export default PageSection;