import React, { useState } from 'react';
import { Footnote } from '../TwoColInfoBlock/styles';
import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { teal, grayBg } from '../../../styles/shared/colors';

const RecurranceWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  ${respondTo.sm`
      flex-direction: row;
      

  `}
`;
const RecurranceHeaderMobile = styled.h3`
  display: block;
  color: #4C4C4C;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  background: white;
  padding: 17px 0;
    border-bottom: 2px solid #3CBEB2;
    margin-bottom: 24px;

  &  sup {
    color: ${teal};
  }
  ${respondTo.sm`
    display: none;
  `}
`;
const RecurranceHeaderDesktop = styled.h3`
  display: none;
  ${respondTo.sm`
  display: block;
  color: #4C4C4C;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  background: white;
  padding: 25px 0;
  padding-bottom: 50px;
  padding-top: 85px;
  
  &  sup {
    color: ${teal};
  }
  `}
`;
const GradientBlockWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  


  ${respondTo.sm`
       width: 100%;
       max-width: 581px;
       padding-left: 30px;
       border-left: 3px solid #3CBEB2;
    padding-left: 24px;
  `}

  @media (min-width: 1366px) {
    width: 100%;
  }

  @media (min-width: 765px) {
    width: 50%;
  }
`;

const GradientWrap = styled.div`
  display: flex;
  padding: 0 0 25px 0;
 
  ${respondTo.sm`
    padding: 0 0 17px 0;
    flex-flow: unset;
  `}
  flex-direction: column;
  flex-flow: row wrap;
 

  ${respondTo.sm`
    padding-bottom: 20px;
    flex-direction: row;
  `}
`;
const ImgWrap = styled.div`
  width: 100%;
  /* border-bottom: 2px solid #3CBEB2; */
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;


  ${respondTo.sm`
      width: 100%;
      padding-right: 24px;
      border-bottom: unset;
      padding-bottom: unset;
      margin-bottom: unset;
  `}


  img {
    width: 100%;
    height: 100%;


    ${respondTo.sm`
      width: 411px;
      height: 492px;
    `}

    @media (min-width: 1366px) {
      width: 411px;
    }
    @media (min-width: 765px) {
      width: 100%;
    }
  }
`;

const GradientBlock = styled.div`
  background: ${({gradientMode}) => gradientMode === "green" ? '#3CBEB2' : 'linear-gradient(182deg, #FFF 1.39%, #F2F2F2 98.6%)'};
  padding: 0 0px;
  height: 60px;
  text-align: center;
  color:  ${({gradientMode}) => gradientMode === "green" ? 'white' : `${grayBg}`};
  font-family: Arial;
  width: 100%;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 29.333px;
  line-height: 20px;
  ${({borderRight}) => borderRight &&  'border-right: 0.667px solid rgba(112, 112, 112, 0.50)'};
  ${({borderBottom}) => borderBottom &&  'border-bottom: 0.667px solid rgba(112, 112, 112, 0.50)'};
  

${respondTo.sm`
       width: ${({gradientMode}) => gradientMode === "green" ? '255px;' : '175px;'}
  `}
`;

const GradientCell = styled(GradientBlock)`
  width: 50%;
  height: unset;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  ${({mobileOnly}) => mobileOnly && 'display: block'};

  

  ${respondTo.sm`
    br {
      display: none;
    }
    display: block;
      ${({mobileOnly}) => mobileOnly && 'display: none'};
      width: ${({gradientMode}) => gradientMode === "green" ? '255px;' : '175px;'}



  
  `}


`;

const RecFootnote = styled(Footnote)`
  width: 90%;
  max-width: 761px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 17px 0;
  line-height: 1.3;
  ${respondTo.sm`
    padding: 25px 0;
  `}
`;

const ImgFootnote = styled.div`
  position: absolute;
  bottom: -16px;
`;

const ImgFootnoteRec = styled(RecFootnote)`
  background: none !important;
  font-size: 13px;
`;

const TableFootnote = styled(ImgFootnoteRec)`
  margin-left: 0;
  padding-top: 0;
  .teal {
    color: ${teal};
    text-decoration: none;
  }
`;

const Spacer = styled.div`
  height: 72px;
  background-color: white;
`;

const TableHeaderWrap = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const TableHeaders = styled.div`
  margin-left: auto;
  display: flex;
  font-size: 17px !important;

 
  .hide-mobile {
      display: none;

      ${respondTo.sm`
        display: block;
      `}

  }


  div:first-child {
    margin-left: auto;
  }
  div:last-child {
    margin-right: auto;
  }
  div {
      text-align: center;
      text-align: center;
      font-family: Arial;
      font-style: normal;
      font-size: 17px !important;
      line-height: 23.333px; 
      width: 130px !important;

    b {
      color: #4C4C4C;
      font-weight: 700;
      font-size: 17px !important;
    }


   
    
  }

  ${respondTo.sm`

      div {
        width: 155px;
      }
      div:first-child {
        margin-left: auto;

      }
      div:last-child {
        margin-right: unset;
      }
  `}
`;

const MobileTableHeader = styled(TableHeaders)`

  div {
    font-size: 17px;
    margin-right: auto;


    b {
      font-size: 17px
      
    }
  }
`;



const Recurrance = ({ recurranceImg, recurranceImgAlt, GradientBlockContent}) => {
  

  return (
    <>
      <RecurranceWrap>
        <ImgWrap>
          <img src={recurranceImg} alt={recurranceImgAlt}></img>
          
          <ImgFootnote>
            <ImgFootnoteRec>
            For illustrative purposes only: A visual representation of various stages of MIBC.<sup>7</sup> Based off AJCC, 8th edition.

              
            </ImgFootnoteRec>
          </ImgFootnote>
    
        </ImgWrap>
        <GradientBlockWrap>
      <RecurranceHeaderDesktop>Recurrence by Tumor (T) Stage and Lymph Node Involvement<sup>9</sup></RecurranceHeaderDesktop>
      <RecurranceHeaderMobile>Recurrence by Tumor (T) Stage and Lymph Node Involvement<sup>9</sup></RecurranceHeaderMobile>

      <TableHeaderWrap>
        <TableHeaders>
          <div className='hide-mobile'>
            <b>Early <br/>
            recurrence</b> <br/>
            (n=559)
          </div>
          <div className='hide-mobile'>
            <b>Late <br/>
            recurrence<span className="teal">*</span></b> <br/>
            (n=58)
          </div>
        </TableHeaders>
      </TableHeaderWrap>
          {GradientBlockContent.map((block)=>{
            return(
            <GradientWrap>
              <GradientBlock gradientMode="green">
                {block.disease}
              </GradientBlock>
              <GradientCell mobileOnly={true} borderRight={true} borderBottom={true} gradientMode="white">
              <MobileTableHeader>
                <div>
                  <b>Early <br/>
                  recurrence</b> <br/>
                  (n=559)
                </div>
              </MobileTableHeader>
              </GradientCell>
              <GradientCell mobileOnly={true} borderBottom={true} gradientMode="white">
              <MobileTableHeader>
                <div>
                  <b>Late <br/>
                  recurrence<span className="teal">*</span></b> <br/>
                  (n=58)
                </div>
              </MobileTableHeader>
              </GradientCell>
              <GradientCell borderRight={true} gradientMode="white">
                {block.percentage}
              </GradientCell>
           
              <GradientCell gradientMode="white">
                {block.percentage2}
              </GradientCell>
            </GradientWrap>)
          })}
          <TableFootnote>
            <span className="teal">*</span>Defined as more than 3 years after radical cystectomy.
          </TableFootnote>
        </GradientBlockWrap>
      </RecurranceWrap>
        <Spacer />
    </>
  )
}


export default Recurrance;
