import React from 'react';
import * as s from './styles';
import AnchorZone from '../../../styles/shared/anchorSection';

export default function Index({ 
  headerTitle,
  id,
  headerSubtitle,
  headerOne,
  contentOne,
  headerTwo,
  contentTwo,
  footNote,
  highlightBox,
 }) {


  return (
    <s.Wrapper>
      <s.Header>
        <div className='header-title'>{headerTitle}
        {id &&
          <AnchorZone id={id} offDesktop={-60}/>
          }
        </div>
        { highlightBox && <>
            <p className="highlight-box">{highlightBox}</p>
          </>
        }
        <div className='header-subtitle'>{headerSubtitle}</div>
      </s.Header>
      <s.Root>
        <s.FlexContainer>
          <s.ShadedBox firstBox={true}>
          { headerOne &&
            <div className='title'>
              {headerOne}
            </div>
          }
            {contentOne}
          </s.ShadedBox>
          <s.ShadedBox>
          { headerTwo &&
            <div className='title'>
              {headerTwo}
            </div>
          }
            {contentTwo}
          </s.ShadedBox>
        </s.FlexContainer>
      </s.Root>
    { footNote &&
      <s.Footnote>
        {footNote}
      </s.Footnote>
    }
    </s.Wrapper>
  )
}
