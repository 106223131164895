import React from 'react';
import * as s from './styles';
import AnchorZone from '../../../styles/shared/anchorSection';


export default function Index({
  title,
  middleText,
  bottomText,
  id
}) {
  return (
    <s.Root >
      {id &&
        <AnchorZone id={id} />
      }
      {title && <div className='title'>
        {title}
      </div>}
      {middleText &&
        <div className='middle-text'>
          {middleText}
        </div>}
      {bottomText &&
        <div className='bottom-text'>
          {bottomText}
        </div>}
    </s.Root>
  )
}
