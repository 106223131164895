import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { altBlue, teal } from "../../../styles/shared/colors";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
      gap: 17px;
    
    ${respondTo.sm`
        padding-top: 0;
        gap:  25px;
    `} 

    .title {
        text-align: center;
        font-size:35px;  
        line-height:37px;
        font-weight: 400;
        font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;

        ${respondTo.sm`
            font-size: 63px;
            line-height: 4.3125rem;
        `} 


        span {
            color: ${altBlue};
        }
    }

    .image-container {
        img {
            max-width: 100%;
        }
    }

    .middle-text {
        text-align: center;
        color: rgba(0,0,0,0.7);
        margin: 0 auto;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
       
        font-family: Arial, Helvetica, sans-serif;
        max-width: 70%;
        

        ${respondTo.sm`
            font-size: 1.75rem;
            line-height: 2.25rem;
        `} 

        sup {
            color: ${teal};
        }
    }

    .bottom-text {
       color: black;
       font-size: 22px;
       font-weight: 400;
       text-align: center; 
       margin: 32px 0 40px 0;
       font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;

       ${respondTo.sm`
            font-size: 32px;
            margin: 22px 0 45px 0;
        `} 
    }
`;
