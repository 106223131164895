import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { teal, altTextBlack } from "../../../styles/shared/colors";


export const Wrapper = styled.div`
    color: rgba(0,0,0,0.7);

    .r-ball {
        font-size: 10px;
        top: 0;

        ${respondTo.sm`
            font-size: 14px;
        `}
    }
`;

export const Header = styled.div`
    background-color: white;

    sup {
    color: ${altTextBlack};
    &.change-color {
      color: ${teal};
    }
    }

    .header-title {
        position:relative;
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        line-height: 25px;
        padding: 17px  0;


        ${respondTo.sm`
            line-height: 30px;
            padding: 25px 0;
            font-size: 28px;
            line-height: 24px;
        `}
    }

    .header-subtitle {
        font-size: 15px;
        line-height: 18px;
        padding-bottom: 17px;

        p:last-child {
          margin-bottom: 0;
        }
        ${respondTo.sm`
            font-size: 21px;
            line-height: 27px;
            padding-bottom: 25px;
        `}
    }

`;

export const Root = styled.div`
    border-top: 3px solid ${teal};
    border-bottom: 3px solid ${teal};
    font-family: Arial, Helvetica, sans-serif;
    background: white;
    color: rgba(0,0,0,0.7);
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > div:nth-child(2) {
    border-top: 3px solid ${teal};

    }

    ${respondTo.sm`
        flex-direction: row;

    & > div:nth-child(2) {
      border-top: 0;

    }
    `}
`;

export const Box = styled.div`
    padding: 17px;
    width: 100%;
    border-bottom: 3px solid ${teal};

     ${respondTo.sm`
        padding: 25px;
        width: 50%;
        border-bottom: none;
    `}

  p {
    margin-bottom: 17px;
    ${respondTo.sm`
      margin-bottom: 25px;
    `}
  }
    .title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        padding-bottom: 17px;

        ${respondTo.sm`
            font-size: 28px;
            line-height: 36px;
            padding-bottom: 25px;
        `}

        sup {
            font-size: 9px;
            color: ${teal};

            ${respondTo.sm`
                font-size: 14px;
            `}
        }


    }

    .content {
        font-size: 14px;
        line-height: 18px;
        padding-top: 18px;


        ${respondTo.sm`
            font-size: 16px;
            line-height: 22px;
        `}

        sup {
            color: ${teal};
            font-size: 7px;

            ${respondTo.sm`
                font-size: 8px;
            `}
        }
    }
`;

export const Footnote = styled.div`
    background-color: white;
    padding: 17px 0;
    font-size: 16px;


${respondTo.sm`
    padding: 25px 0;
    `}
  p {
    font-size: 11px;
    margin-bottom: 0;
    line-height: 1.5;
    ${respondTo.sm`
      font-size: 13px;
    `};
  }

    //TO DO: this will probably be a link
    span {
        text-decoration: underline;
      color: ${altTextBlack};
      a {
        color: ${altTextBlack};
        }
    }
`;

export const ShadedBox = styled(Box)`
background: ${props => props.firstBox ? 'linear-gradient(to bottom, #f5f5f5 0%, rgba(220,220,220,0) 85%)' : 'linear-gradient(to bottom, #d9d9d9 0%, #f2f2f2 85%)'};
    border-bottom: none;
`;
