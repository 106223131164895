import React from 'react';
import * as s from './styles';

export default function Index({
  children,
  className,
}) {
  return (
    <s.Root className={className}>
        {children}
    </s.Root>
  )
}

