import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { teal } from "../../../styles/shared/colors";

export const Root = styled.div`
    background-color: white;
    z-index: 9001;
    border-top: 3px ${teal} solid;
    border-bottom: 3px ${teal} solid;
    padding: 17px 0;
    margin: 0 auto;
  ${respondTo.sm`
    padding: 25px 0;
    flex-flow: row-wrap;
    row-gap: 16px;
`}

    .headline {
        text-align: center;
        font-size: 21px;
        font-weight: 800;
        padding: 0 40px 0 40px;
        font-size: 24px;
        line-height: 29px;
        color: #4c4c4c;
        
        @media screen and (-webkit-min-device-pixel-ratio:0) {
            font-weight: bolder;
        }
        @-moz-document url-prefix() {
            font-weight: bolder;
        }

        ${respondTo.sm`
            font-size: 30px;
            line-height: 26.6px;
            padding: 0 15px 12px 15px;
        `} 

        sup {
            color: ${teal};
        }
    }

    .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        padding-top: 17px;

        ${respondTo.sm`
            flex-direction: row;
            justify-content: space-between;
            gap: 0;
            padding-top: 25px;
            padding: 25px 0;
            flex-flow: row wrap;
            row-gap: 16px;
        `} 
    }
`;
