import React from 'react';
import * as s from './styles';
import IconWithText from '../IconWithText';

export default function index({ 
  displayHeadline, 
  data
}) {
  return (
    <s.Root>
      {
        displayHeadline &&
        <div className='headline'>For an Optimized Treatment Strategy, the Following Topics Are Critical for Discussion<sup>8</sup></div> 
      }
      <div className='flex '>
        {
          data.map((item, i) => (
            <IconWithText
              key={i}
              image={item.image}
              text={item.text}
            />
          ))
        }
      </div>
    </s.Root>
   
  )
}

index.defaultProps = {
  displayHeadline: false
}
