import styled from 'styled-components';
import { respondTo } from '../shared/breakpoints';
import { greyBorder } from '../shared/colors';

export const HomePage = styled.div`
    position: relative;
    /* margin-top: 100px; */
    min-height: 1500px;
    ${respondTo.md`
        padding-left: 220px;

    `}


    section:nth-child(2) {
        ${respondTo.sm`
            padding: 48px 0 56px 0;
        `}
        .pageSectionWrapper {
            /* &:after {
                content: '';
                height: 2px;
                background-color: ${greyBorder};
                position: absolute;
                bottom: -42px;
                width: calc(100% - 40px);
                left: 20px;
                ${respondTo.sm`
                    width: calc(100% + 30px);
                    left: -15px;
                    bottom: -56px;
                `}
            } */
        }
    }

    /* section:nth-child(3) {
        ${respondTo.sm`
            padding: 54px 0 58px 0;
        `}
        p:first-of-type {
            margin-bottom: 16px;
            ${respondTo.sm`
                margin-bottom: 20px;
            `}
        }
        p:last-of-type {
            margin-bottom: 0px;
        }
    } */


    .font-size-22 {
        font-size: 22px;
    }

    .text-center {
        text-align: center;
    }

    .adds-margin-bottom {
        margin-bottom: 16px;
    }

    .text-left {
        text-align: left;
    }

    .margin-left {
        margin-left: 32px;
    }

    .text-white {
        color: white;
    }

    .bg-white {
        background: white;
        background-color: white;
    }
    
    .margin-bottom-32 { 
        margin-bottom: 32px;
    }

    .pt-0 {
        padding-top: 0;
    }

    .pb-0 {
        padding-bottom: 0;
    }

    .pb-45 {
        padding-bottom: 45px;
    }

   
    .padded-list {
        padding-left: 1.6em;
        list-style: disc;
        margin-bottom: 1em;

        li {
            margin-bottom: .5em;
            line-height: 1.3;
        }
    }

    .highlight-box {
        background-color: lightgray;
        margin-bottom: 32px;
        padding: 1em;
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
        text-align: center;
        ${respondTo.sm`
            font-size: 21px;
            line-height: 27px;
        `}
    }

    p {
        line-height: 1.3;
        margin-bottom: 1em;
    }
`;



