import React, { useState } from 'react';
import { Footnote } from '../TwoColInfoBlock/styles';
import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';


const PotentialRoot = styled.div`
  width: 100%;
  max-width: 761px;
  margin: 0 auto;
  display: flex; 
  flex-direction: column;
  gap: 17px;
  padding: 17px 0;
  ${respondTo.sm`
  padding: 25px 0;
    gap: 25px;
  `}
`;

const PotentialHeader = styled.h3`
  color: #4C4C4C;
  margin: 0;
  font-family: Arial;
  font-size: 26.667px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  span {
    color: #5450E3;
  }


`;

const PotentialContent = styled.div`
  padding: 17px 0;
  display: flex;
  flex-direction: column;
  gap: 17px;
  border-top: 2px solid #3DBEB2;
  border-bottom: 2px solid #3DBEB2;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.333px;
  background-color: white;
  text-align: center;
  ${respondTo.sm`
    gap: 25px;
    padding: 25px 0;
  `}
  p {
    margin-bottom: 0;
    padding-left: 16px;
    padding-right:16px;

  }

  .r-ball {
    color: inherit;
    top: 0;
    font-size: 14px;
  }
 
  sup {
    white-space: nowrap;
  }

`;

const Recurrance = ({ recurranceImg, GradientBlockContent}) => {
  

  return (
    <PotentialRoot>
      <PotentialHeader>What is the potential benefit of <span>neoadjuvant chemotherapy</span>?</PotentialHeader>
      <PotentialContent>
        <p>According to the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup className='r-ball'>®</sup>):</p>
        <p>National Comprehensive Cancer Network<sup className='r-ball'>®</sup> (NCCN<sup className='r-ball'>®</sup>) recommends cisplatin-based neoadjuvant chemotherapy followed by radical cystectomy for certain patients with stage ll or lllA bladder cancer.<sup>1</sup></p>
      </PotentialContent>
    </PotentialRoot>
  )
}


export default Recurrance;
