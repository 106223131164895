import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { altPink, textBlack, teal } from "../../../styles/shared/colors";

export const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    gap: 17px;
    padding: 17px 0;
  
    ${respondTo.sm`
        padding: 25px 0;
        max-width: 952px;
        gap: 25px;
    `} 

    .title {
        text-align: center;
        font-size: 37px;
        font-weight: 400;
        line-height: 3rem ;
        font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
        background-color: white;

        ${respondTo.sm`
            font-size: 65px;
            line-height: 4.3125rem;
        `}
        span {
            color: ${altPink};
        }
    }

    .image-container {
        img {
            max-width: 100%;
        }
    }

    .middle-text {
        text-align: center;
        color: ${textBlack};
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        color: rgba(0,0,0,0.7);
        font-size: 20px;
        line-height: 25px;

        ${respondTo.sm`
           font-size: 1.75rem;line-height: 2.25rem;
            line-height: 36px;
            padding-left: 35px;
            padding-right: 35px;
        `} 

        sup {
            color: ${teal};
        }
    }

    .bottom-text {
        text-align: center;
        color: #585858;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        line-height: 18px;
        ${respondTo.sm`
            font-size: 21px;
            line-height: 27px;
            padding: 0 75px 0 75px;
        `} 

        sup {
            color: ${teal};
        }
    }
`;
