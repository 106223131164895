import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { textBlack } from "../../../styles/shared/colors";

export const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${respondTo.sm`
        flex-direction: column;
        width: 33.3%;

    `} 



    .image-container {
       /* margin-left: auto;
       margin-right: auto;
  */

        ${respondTo.sm`
            padding-right: 0;
        `} 

        img {
            height: 100px;
            width: 100px;

            ${respondTo.sm`
                width: 150px;
                height: 150px;
            `} 
        } 
    }

    .text-container {
        text-align: center;
        color: ${textBlack};
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        color: rgba(0,0,0,0.7);
        font-size: 24px;
        line-height: 28px;
        padding: 0px 0px 0px 10px;
        width: 150px;

        ${respondTo.sm`
            font-size: 20px;
            line-height: 25px;
            padding: 18px 20px 0 10px;
            width: unset;
        `} 
    }
`;