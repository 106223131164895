import React from 'react';
import styled from 'styled-components';



const Item = styled.div`
  &:before {
    border-left: 2px solid white;
    border-top: 2px solid white;
    content: '';
    display: block;
    height: .75rem;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: .75rem;
    margin-left: -15px;
  }
`;

export default function NewCaret({className}) {
  return (
        <Item className={className}></Item>

  )
}
