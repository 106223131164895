import React from 'react';
import * as s from './styles';

export default function index({
    image,
    text
}) {
  return (
    <s.Root>
      <div className='image-container'>
        <img 
          className='image-container'
          src={image} 
          alt=''
        />
      </div>
        <div className='text-container'>{text}</div>
    </s.Root>
  )
}
