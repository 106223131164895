import React from 'react';
import { respondTo } from '../../../styles/shared/breakpoints';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 17px;

  ${respondTo.sm`
  position:  absolute;
  left: 15px;
  top: 12px;
  `};
`;

const Outter = styled.div`
  position: relative;
  left: 40%;
  height: 1.5rem;
  width: 1px;
  background-color: white;
  transform: rotate(45deg);
  Z-index: 1;
`;

const Inner = styled.div`
  height: 1.5rem;
  width: 1px;
  background-color: white;
  transform: rotate(90deg);
  Z-index: 2;
`;

export default function X({ onClick }) {
  return (
    <Root onClick={onClick}>
      <Outter>
        <Inner></Inner>
      </Outter>
    </Root>
  )
}
