import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { textBlack, teal, altTextBlack } from "../../../styles/shared/colors";

export const Wrapper = styled.div`
    z-index: 2;
    width: 100%;
    font-family: arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    background: white;
    color: rgba(0,0,0,0.7);

    .intro {
        text-align: center;
        font-weight: 400;
        padding: 12px 0 24px 0;
        font-size: 18px;
        line-height: 20px;

        ${respondTo.sm`
            padding: 18px 0 32px 0;
            font-size: 28px;
        `} 

        sup {
            font-size: 7px;

            ${respondTo.sm`
                font-size: 16px;
            `} 
        }
    }

    .header {
        text-align: center;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
        padding: 17px 0;
    ${respondTo.sm`
      padding: 25px ;
      `}

        sup {
            color: ${teal};
            font-size: 10px;

            ${respondTo.sm`
                font-size: 11px;
            `} 
        }
    }

    .headline {
        color: ${altTextBlack};
        text-align: center;
        font-size: 18px;
        padding-bottom: 32px;

        ${respondTo.sm`
            font-size: 30px;
            font-weight: 400;
        `} 
    }

    .regular-content {
        color: ${altTextBlack};
        font-size: 14px;
        line-height: 18px;

        ${respondTo.sm`
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        `}
    }

    .bot-box {
        padding: 14px 28px;

        ${respondTo.sm`
            margin: 0 32px;
            padding: 32px;
        `}
    }

    .bottom-headline {
        font-size: 20px;
        line-height: 25px;
        padding-bottom: 20px;
        text-align: center

        ${respondTo.sm`
            margin-bottom: 20px;
            font-size: 28px;
            font-weight: 400;
        `} 
    }

    .border-top {
        border-top: 3px solid ${teal};
    }
`;

export const Box = styled.div`
    background: linear-gradient(to bottom, #dcdcdc 0%, rgba(220,220,220,0) 100%);
`;

export const BottomBox = styled(Box)`
    border-bottom: 3px solid ${teal};

    ul {
        list-style-type: disc !important;
        padding-bottom: 16px;
        font-size: 16px;
        line-height: 20px;

        ${respondTo.sm`
            font-size: 18px;
            line-height: 22px;
        `}

        li {
            ul {
                padding-bottom: 0;
                li {
                    margin-left: 18px;
                    padding-top: 12px;
                }
            }
        }
    }
`;
