import React from 'react';
import * as s from './styles';

export default function Index( { 
    top,
    second,
    definitions,
    imgSrc,
    bottom
 }) {
  return (
    <s.Root>
        <div className='top-line'>
            {top}
        </div>
        <div className='second-text'>
            {second}
        </div>
        <div className='definitions'>
            {definitions}
        </div>
        <div>
            <img src={imgSrc} alt='' />
        </div>
        <div className='bottom-text'>
            {bottom}
        </div>
    </s.Root>
  )
}
