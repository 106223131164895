import React from 'react';
import TopShadedBox from '../components/elements/TopShadedBox';
import SlidePopUp from '../components/elements/SlidePopUp';
import NewHero from '../components/elements/NewHero';
import SecondaryHero from '../components/elements/SecondaryHero';
import homeCopy from '../copy/home';
import * as s from '../styles/pages/home';
import PageSeo from '../components/elements/Seo/PageSeo';
import TwoColInfoBlock from '../components/elements/TwoColInfoBlock';
import PageSection from '../components/layout/PageSection';
import TextBlock  from '../components/elements/TextBlock';
import Line from '../components/elements/Line';
import styled from 'styled-components';
import recurrance from "../assets/images/recurrance_img.png";
import { respondTo } from '../styles/shared/breakpoints';
import AnchorZone  from '../styles/shared/anchorSection';
import { useInView } from 'react-intersection-observer';
import Header from '../components/elements/Header';
import IconFlexWrapper from '../components/elements/IconFlexWrapper';
import Recurrance from '../components/elements/Recurrance';
import Potential from "../components/elements/Potential";
import TopicsComp from "../components/elements/TopicsComp";

const Spacer = styled.div`
  height: 56px;

  ${respondTo.sm`
      height: 112px;
  `}
`;

const Spacer25 = styled.div`
  height: 25px;
`;

const MobileOnly = styled.div`
  display: block;
  z-index: 9000;

  ${respondTo.sm`
    display: none;
  `}
`;

const DesktopOnly = styled.div`
  display: none;
  z-index: 9000;

  ${respondTo.sm`
    display: block;
  `}
`;

const StyledHeader = styled.h3`
  font-size: 22px;
  line-height: 25px;
  text-align: center;

  ${respondTo.sm`
    font-size: 32px;
    line-height: 36px;
  `}
`;

const GradientBlockContent = [
  {disease: 'pT2', percentage: <>121 <br/>(22%)</>, percentage2: <>19 <br/>(33%)</>},
  {disease: 'pT3', percentage: <>241 <br/>(43%)</>, percentage2: <>15 <br/>(26%)</>},
  {disease: 'pT4', percentage: <>143 <br/>(26%)</>, percentage2: <>5 <br/>(8.6%)</>},
  {disease: <>Lymph node involvement (n=594)</>, percentage: <>237 <br/>(44%)</>, percentage2: <>11 <br/>(20%)</>},
];

const ColorBG = styled.div`background: rgba(255, 0, 0, 0.10);`;

const AnchorTarget = ({height, heightMobile, top})=>{

}

const IndexPage = () => {
 
  const NeoInView = useInView({
    /* Optional options */
    threshold: 0,
  });

  const WhyInView = useInView({
    /* Optional options */
    threshold: 0,
  });

  const MIBCInView = useInView({
    /* Optional options */
    threshold: 0,
  });

  const ConsiderationsInView = useInView({
    /* Optional options */
    threshold: 0,
  });


  return (
    <>
    <Header NeoInView={NeoInView} WhyInView={WhyInView} MIBCInView={MIBCInView} ConsiderationsInView={ConsiderationsInView} />

    <s.HomePage>
      
      <PageSeo pageTitle={ homeCopy.seo.pageSeoTitle } pageDescription={ homeCopy.seo.pageDescription } pageKeywords={ homeCopy.seo.pageKeywords }> 
        <link rel="canonical" href="https://www.whyneoadjuvant.com/"/>
        <link rel="icon" href="favicon.svg" type="image/svg+xml"></link>
      </PageSeo>
        <div style={{position: 'absolute',height: '1200px', width:'100vw', top: 0}}  ref={NeoInView.ref}></div>
        <div style={{position: 'absolute', height: '400px', top: '2000px', width: '100vw'}} ref={WhyInView.ref}></div>
        <div style={{position: 'absolute', height: '1325px', top: '3520px', }} ref={MIBCInView.ref}></div>
        <div style={{position: 'absolute', height: '2145px', top: '5750px', }}  ref={ConsiderationsInView.ref}></div>

        <PageSection  >
          <Spacer25 />
            <AnchorZone id={"neo"}/>
          <NewHero 
            title={homeCopy.hero.title}
            heroImg={homeCopy.hero.heroImg}
            middleText={homeCopy.hero.middleText}
            bottomText={homeCopy.hero.bottomText}
          />
        </PageSection>

        <div style={{position: 'relative'}}>
            <Line heightOverride="180%" header={<h3 className='text-center '>Find out why.</h3>}/>          
    <Spacer />
            <PageSection >
              <SlidePopUp 
                slidePopUpData={homeCopy.slidePopUps}
                slideModalData={homeCopy.slideModalData}
              />
            </PageSection>
            <PageSection>
              <TextBlock
                title={homeCopy.textBlockOne.title}
                middleText={homeCopy.textBlockOne.middleText}
                bottomText={homeCopy.textBlockOne.bottomText} 
                id="mibc"
            />

            </PageSection>
        </div>
    <Spacer />

         <PageSection >
          <IconFlexWrapper
            displayHeadline={true}
            data={homeCopy.iconWithTextData}
          />
        </PageSection>

    <Spacer />
        
        <div style={{position: 'relative'}}>
            <Line header={<StyledHeader>See why neoadjuvant therapy may be considered for select patients.</StyledHeader>}/>          

            <PageSection className={"pb-0"} >
    <Spacer />
              <TextBlock
                id="why"
                title={homeCopy.textBlockTwo.title}
                middleText={homeCopy.textBlockTwo.middleText}
                bottomText={homeCopy.textBlockTwo.bottomText} />
            </PageSection>

            <PageSection className={"pb-0"} >
                <Recurrance recurranceImg={recurrance} GradientBlockContent={GradientBlockContent} recurranceImgAlt="Visual Representation of Various Stages of Muscle Invasive Bladder Cancer (MIBC)"/>
            </PageSection>
            <PageSection purple={true} className={"pt-0 pb-0"}>
              <Potential />
            </PageSection>
            </div>
    <Spacer />
          <PageSection className={"bg-white"}>
            <TopShadedBox 
              boxOneHeader={homeCopy.topShadedBox.topBox[0].header}
              boxOneBody={homeCopy.topShadedBox.topBox[0].bodyContent}
              boxThreeHeader={homeCopy.topShadedBox.bottomBox[0].header}
              boxThreeBody={homeCopy.topShadedBox.bottomBox[0].bodyContent}
              boxFourHeader={homeCopy.topShadedBox.bottomBox[1].header}
              boxFourBody={homeCopy.topShadedBox.bottomBox[1].bodyContent}
            />
            {/* <Footnote className="text-left margin-left">
            HR = hazard ratio; CI = confidence interval; NAC = neoadjuvant chemotherapy.
            </Footnote> */}
          </PageSection>

          <div style={{position: 'relative'}}>
          <Line heightOverride='100%' topOverride={'90px'}   />         
    <Spacer />
          <PageSection className={"pb-0"}>
            <TextBlock  id={"con"} title={"Considerations for surgery and neoadjuvant therapy"} />
          </PageSection>

          <PageSection className={"pb-0"}>
            <TwoColInfoBlock 
              id={"surgery"}
              headerTitle={"When considering surgery"}
              headerSubtitle={<>The AUA recommends this strategy<sup className={'change-color'}>8</sup>:</>}
              contentOne={<><p>A cisplatin-based neoadjuvant chemotherapy should be offered to eligible radical cystectomy patients prior to cystectomy.</p></>}
              contentTwo={<><p>Radical cystectomy with bilateral pelvic lymphadenectomy should be offered to surgically eligible patients with resectable nonmetastatic (M0) muscle-invasive bladder cancer.</p></>}
    footNote={
                <>
      <p>AUA = American Urological Association.</p>
      </>}
            />
          </PageSection>

          <PageSection className={"pt-0 pb-0"} purple={true}>
            <TopicsComp />
        
          </PageSection>
          <PageSection className={"pt-0"}>
            
            <TwoColInfoBlock 
              id={"therapy"}
              headerTitle={'When considering neoadjuvant therapy'}
              headerSubtitle={<><p>For some patients, NCCN recommends neoadjuvant chemotherapy over adjuvant-based chemotherapy.<sup className='change-color'>1</sup></p><p>NCCN recommends that patients meet these criteria.</p></>}
              highlightBox={null}
              headerOne={<strong>Patients with MIBC who are candidates for surgery</strong>}
              contentOne={<>
                <p>NCCN recommends cisplatin-based neoadjuvant chemotherapy followed by radical cystectomy for certain patients with stage II or IIIA bladder cancer.<sup className='second-text-sup'>1</sup></p>
                <p>Neoadjuvant cisplatin-based therapy + radical cystectomy + pelvic lymph node dissection is a recommended treatment option for:</p>
                <ul class="padded-list">
                  <li>
                  Stage ll = cT2, N0, M0<sup>1</sup>
                  </li>
                  <li>
                  Stage lllA = cT3, N0, M0: cT4a, N0, M0; cT1&ndash;4a, N1<sup>1</sup>
                  </li>
                  <li>
                  Patients willing to accept urinary diversion<sup>8</sup>
                  </li>
                </ul>
                </>}
              headerTwo={<strong>Patients with MIBC who are cisplatin-eligible</strong>}
              contentTwo={
                <>
                <p>Factors to consider<sup className='change-color'>1,16</sup>:</p>
                <ul class="padded-list">
                  <li>Hearing</li>
                  <li>Performance status</li>
                  <li>Renal function</li>
                  <li>Neuropathy</li>
                  <li>Heart failure</li>
                </ul>
                </>}
                footNote={<>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</>}
            />
{/*        
            <TextBlock   
              title={null}
              middleText={"Patients with MIBC who are not candidates for neoadjuvant therapy"}
              bottomText={<>If cisplatin-based chemotherapy cannot be given, neoadjuvant chemotherapy is not recommended.<sup>2</sup></>} /> */}
          </PageSection>
    <Spacer />
          <PageSection className={"pb-0"}>
            
            <SecondaryHero 
              top={homeCopy.secondaryHero.top}
              second={homeCopy.secondaryHero.second}
              definitions={homeCopy.secondaryHero.definitions}
              imgSrc={homeCopy.secondaryHero.heroImg}
              bottom={homeCopy.secondaryHero.bottom}
            />
          </PageSection>
          </div>

       
    </s.HomePage>
    </>
  )
}

export default IndexPage;
