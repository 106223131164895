import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { altPink, charcoal, teal, textBlack } from "../../../styles/shared/colors";

export const Root = styled.div`
    text-align: center;
    background-color: white;
    overflow: hidden;
    padding-top: 17px;
  ${respondTo.sm`
        padding-top: 25px;
    `}

    .hide-desktop{
        display: block;

        ${respondTo.sm`
            display: none;
        `}
    }

    .top-line {
        font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
        font-weight: 400;
        font-size: 30px;
        line-height: 33px;
        padding-bottom: 17px;

        
        ${respondTo.sm`
            font-size: 50px;
            line-height: 72px;
            padding-bottom: 25px;
        `}

        span {
            color: #5450E3;
        }

    }

    .second-text {
        color: ${charcoal};
        font-size: 15px;
        line-height: 18px;
        padding: 0 20px 17px 20px;

        sup {
             display: inline-block;
        }

        ${respondTo.sm`
            padding: 0 100px 25px 120px;
            font-size: 28px;
            line-height: 36px;
        `}

        span {
            font-weight: 600;
        }

        sup {
            color: ${teal};
            font-family: "arial", Helvetica, sans-serif;
            font-size: 7.5px;
            
        

            ${respondTo.sm`
                
                font-size: 14px;
                
            
            `}
        }
    }

    .definitions {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 20px;

        ${respondTo.sm`
            font-size: 16px;
            line-height: 22px;
        `}
    }

    img {
        width: 100%;
        padding-bottom: 25px;

        ${respondTo.sm`
            padding-bottom: 0;
        `}
    }

    .bottom-text {
        font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
        color: ${textBlack};
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin-top: -30px; //TODO: pic is very big and creates a lot of space

        ${respondTo.sm`
            font-size: 32px;
            line-height: 40px;
            margin-top: -50px; //TODO: pic is very big and creates a lot of space
        `}
    }
   
`;
