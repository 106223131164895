import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';

export const LineCenter = styled.div`
    transition: all 4.4s;
    transition-timing-function: linear;
    background: #3cbeb2;
    position: absolute;
    height: 150%;
  ${({topOverride})=>topOverride && `top: ${topOverride};` }
    ${({heightOverride})=>heightOverride && `height: ${heightOverride};` }
  top: 0;

  ${respondTo.sm`
    height: 150%;
  ${({topOverride})=>topOverride && `top: ${topOverride};` }
    ${({heightOverride})=>heightOverride && `height: ${heightOverride};` }
    `}

    width: 3px;
    right: calc(50% - 1px);
    z-index: -1;

    animation: slideDown 4s linear;

    @keyframes slideDown{
        from {
            height: 0%;
        }
        to {
            height: 100%;
        }
    }

`;


export const ArrowHeaderWrap = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    gap: 17px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 17px 0;

    ${respondTo.sm`
      gap: 25px;
      padding: 25px 0;
    `}

  h3 {
      font-size: 20px;
      line-height: 25px;
      gap: 25px;
      padding: 0;
      background-color: white;
      ${respondTo.sm`
          font-size: 2rem;
          line-height: 2rem;
      `} 
      font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
    }
`;

export const Arrow = styled.svg`
  fill:#3cbeb2;
  width: 98px;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  display: none;
  background-color: white;

  ${respondTo.sm`
    display: block;
  `} 
`;


export const MobileArrow = styled.svg`
  fill:#3cbeb2;
  width: 43px;
  margin-left: auto;
  margin-right: auto;
  height: 15px;
  display: block;
  
  ${respondTo.sm`
    display: none;
  `} 
`;

const Line = ({className, header, topOverride, heightOverride, linkTo}) => {
 
  return (
    <> 
      <ArrowHeaderWrap className={className}>
        {header}
        <Arrow>
          <defs>
            <clipPath id="clip-path" transform="translate(-0.44 -0.29)">
              <rect className="cls-1" x="0.12" y="1.58" width="97.08" height="39.34"/>
            </clipPath>
          </defs>
          <title>down-arrow</title>
          <g class="cls-2">
            <polygon class="cls-3" points="48.16 39.67 0 2.83 2.13 0.05 48.16 35.26 94.25 0 96.38 2.78 48.16 39.67"/>
          </g>
        </Arrow>
          <MobileArrow >
            <g>
              <defs>
                <rect id="SVGID_1_" x="0" y="0" width="39.6" height="11.9"/>
              </defs>
          
              <polygon class="st0" points="19.8,11.9 0,2.7 1.3,0 19.8,8.6 38.4,0.1 39.7,2.8 	"/>
            </g>
          </MobileArrow>
        </ArrowHeaderWrap>
        <LineCenter heightOverride={heightOverride} topOverride={topOverride}/>
    </>
  );
};


export default Line;
