import React, { useState } from 'react';
import * as s from './styles';
import Caret from './Caret';
import X from './X'
import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { altBlue } from "../../../styles/shared/colors";



const SlideModal = ({modalContent, closeModal, isOpen}) => {
  
  return <>
     {isOpen && 
      <s.Modal>
        <div className='top-container'>
          <div className='x-container'>
            <X onClick={closeModal}/>
          </div>
        </div>
        {modalContent}
       
      </s.Modal>  
      }
  </>
}

const SlideBox = ({children, modalContent, modalKey, currentModalOpen, className, onClick, closeModal}) => {
  return(    
    <s.Box onClick={onClick} className={`box-popup ${className}`}>
    {children}
      <SlideModal modalContent={modalContent} closeModal={closeModal} isOpen={modalKey === currentModalOpen} />
    </s.Box>
  )
}

const FirstSlideBox = styled(SlideBox)`
  margin-bottom: 17px;
  font-size: 18px;
  margin-right: -30px;
  

  ${respondTo.sm`
      margin-right: 0;
      margin-top: 0;
      padding-right: 110px;
  `}
`;

const BoxAnchorWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 15px;
  
  ${respondTo.sm`
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0;
    margin-right: 0;
  `}
`;

const BoxAnchor = styled.div`
  background-color: ${altBlue};
  padding: 1.5em 25px;
  position: relative;
  text-align: center;
  font-weight: 700;
  color: white;
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  display: flex;
  height: 100px;


  div {
    margin-top: auto;
    margin-bottom: auto;
  }


  ${respondTo.sm`
      width: 360px;
      padding: 30px 65px;
  `}
`;


const BoxCaret = styled(Caret)`
    &:before{
      margin-left: 0;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%) rotate(-135deg);

      ${respondTo.sm`
        right: 20px;
        `}
    }
  

`;

export default function Index({ 
  slidePopUpData,
  slideModalData
}) {

  const [currentModalOpen, setCurrentModalOpen] = useState(null);

  const handleCloseModal = (modalKey) => {
    setCurrentModalOpen(null);
  };

  const handleOpenModal = (modalKey) => {
    if (modalKey === currentModalOpen) {
      return;
    }
    setCurrentModalOpen(modalKey);
  };

  const handleButtonClick = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({
  behavior: 'smooth',
  block: 'start',
  inline: "nearest"
})
  }


  return (
    <s.Root>
        <s.FirstBox modalKey="first"
         currentModalOpen={currentModalOpen}
         onClick={()=>{setCurrentModalOpen("first")}} >
                {slidePopUpData.firstBox.content}
        </s.FirstBox>
 
        <BoxAnchorWrap>
          <BoxAnchor onClick={()=>{handleButtonClick("surgery")}}><BoxCaret/><div>When considering surgery</div></BoxAnchor>
          <BoxAnchor  onClick={()=>{handleButtonClick("therapy")}}><BoxCaret/>When considering neoadjuvant therapy</BoxAnchor>
        </BoxAnchorWrap>
        <FirstSlideBox 
         modalContent={slideModalData[0].content}
         modalKey="second"
         currentModalOpen={currentModalOpen}
         onClick={()=>{handleOpenModal("second")}}
         closeModal={()=>{handleCloseModal("second")}}
        >
          <Caret />
          <s.CaretText>
            {slidePopUpData.boxes[0].content}
          </s.CaretText>
        </FirstSlideBox> 
       
    </s.Root>
  )
}
