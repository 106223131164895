import React from 'react';
import scalpelImg from '../assets/images/hcp-neo-scalpel--desktop.png';
import Footnote from '../components/elements/Footnote';
import chartMobile from "../assets/images/chart-mobile.svg"
import chartDesk from "../assets/images/chart-desktop.svg"
import SurgicalIntervention from '../assets/icons/icon-surgical-intervention.svg';
import NeoadjuvantTherapy from '../assets/icons/icon-neoadjuvant-therapy.svg';
import PatientPreference from '../assets/icons/icon-patient-preference.svg';
import LifestyleConsiderations from '../assets/icons/icon-lifestyle-considerations.svg';
import PotentialRiskandBenefits from '../assets/icons/icon-potential-risk-and-benefits.svg';
import TumorChar from '../assets/icons/icon-tumor-char.svg';
import styled from 'styled-components';
import { respondTo } from '../styles/shared/breakpoints';

const MobileOnly = styled.div`
  display: block;
  z-index: 9000;

  ${respondTo.sm`
    display: none;
  `}
`;
const DesktopOnly = styled.div`
  display: none;
  z-index: 9000;
  img {
    width: 100%;
    min-width: 550px;
    max-width: 900px;
  }

  ${respondTo.sm`
    display: block;
  `}
`;


const homeCopy = {

  seo: {
    pageSeoTitle: 'Neoadjuvant Therapy for Muscle Invasive Bladder Cancer (MIBC)',
    pageDescription: 'Neoadjuvant therapy is a preoperative systemic therapy that may benefit certain patients with MIBC. Learn how to identify the right candidates for neoadjuvant therapy.',
    pageKeywords: 'neoadjuvant therapy, muscle invasive bladder cancer, mibc',
  },

  hero: {
    title: <>Neoadjuvant Therapy<strong><span>+</span></strong>Surgery Together</>,
    heroImg: scalpelImg,
    middleText: <>Preoperative systemic cisplatin-based therapy may benefit certain patients with MIBC<sup>1&ndash;3</sup></>,
    bottomText: 'Find out why.'
  },

  secondaryHero: {
    top: <>Consider <span>Neoadjuvant Therapy</span></>,
    second: <>for your appropriate stage ll and lllA patients with MIBC.<sup className="second-text-sup">1</sup></>,
    definitions: <></>,
    heroImg: scalpelImg,
    bottom: ''
  },

  textBlockOne: {
    title: <>What is the role of the multidisciplinary team in MIBC?<sup>8</sup> </>,
    middleText: <>The multidisciplinary approach in MIBC creates a platform to discuss potential risks and benefits of all accepted forms of therapy.<sup>8</sup></>,
    bottomText: <>After taking into account patient preference, the urologist and medical oncologist should determine if the patient is a good candidate for neoadjuvant therapy + surgery.<sup>8</sup></>
  },

  textBlockTwo: {
    title: "Why neoadjuvant therapy?",
    middleText: "The risk of recurrence after surgery is a clinical concern.",
    bottomText: <>In a retrospective single-institution study of 2,315 patients with urothelial carcinoma, soft tissue recurrence outside of the urinary tract was assessed in 617 patients after undergoing radical cystectomy between 2000 and 2014. Disease characteristics, including consensus T-stage and histology, were studied. In addition, select treatment factors studied were use of intravesical therapy, preoperative systemic chemotherapy, adjuvant chemotherapy, and salvage chemotherapy.<sup>9</sup><br/><br/>The majority of soft tissue recurrences outside of the urinary tract took place ≤3 years after surgery.<sup>9</sup></>
  },


  topShadedBox: {
    topBox: [
      {
        header: <>Pathologic Complete Response (pCR)<sup>15</sup></>,
        bodyContent: <>
        <div className='regular-content'>
        Defined as the lack of detectable evidence of tumor in tissue samples removed during surgery or biopsy after treatment with radiation or chemotherapy
        </div>
       
      </>
      },
    ],
    bottomBox: [
      {
      header: <>Event-Free Survival (EFS)<sup>12</sup></>,
      bodyContent: 
      <>
        <p>Defined as the time from randomization to any of the following: Disease progression that precludes surgery, local or distant recurrence, or death from any cause</p>
      </>
    },
      {
      header: <>Overall Survival (OS)<sup>12</sup></>,
      bodyContent: 
      <>
        <p>Defined as the time from randomization until death from any cause and is measured in the intent-to-treat population</p>
      </>
    },
  ]
  },
  
  slidePopUps: {
    firstBox: {
      //className='hide-desktop' is accessible for this component if needed
      content: <>Neoadjuvant cisplatin-based chemotherapy is a systemic therapy given before surgery to help
      downstage the tumor and may reduce the
      risk of recurrence.<sup>1,4&ndash;6</sup></>
    },
    boxes: [
      {
        content: <>What is stage ll and lllA MIBC?</>
      },
      {
        content: <>What is high-risk <br className='hide-desktop'/>breast cancer?</>
      }
    ]
  },

  video: {
    title: 'Discover how this surgeon works with her multidisciplinary team',
    // videoSrc: homeVideo,
    bottomText: <><strong>Monica Morrow, MD</strong><br/>
    Surgical Oncologist</>
  },

  twoColInfoBlockData: [
    {
      title: 'Potential preoperative benefits include:',
      content: <>
        <div className='content'>
          <>Downstaging the tumor<sup>3</sup></>
        </div>
        <div className='content'>
          <>Decreasing the degree of nodal involvement<sup>4,5</sup></>
        </div>
        <div className='content'>
          <>Minimizing the extent of axillary surgery and subsequent postoperative complications, such as lymphedema<sup>4,5</sup></>
        </div>
        <div className='content'>
          <>Allowing time for genetic testing<sup>3</sup></>
        </div>
        <div className='content'>
          <>Conserving breast tissue<sup>2,3</sup></>
        </div>
        <div className='content'>
          <>Improving breast cosmesis<sup>4</sup></>
        </div>
        <div className='content'>
          <>Assessment of tumor response to systemic therapy<sup>2,3</sup></>
        </div>
      </>
    },
    {
      title: <>Potential long-term benefits in neoadjuvant patients who achieved a pathological complete response (pCR) (vs those who did not) may include<sup>3,b</sup>:</>,
      content: <>
        <div className='content'>
          <>Favorable event-free survival<sup>2,3</sup></>
        </div>
        <div className='content'>
          <>Favorable overall survival<sup>3,b</sup></>
        </div>
        <div className='content'>
          <>Patients with more aggressive subtypes may have been shown to have an increased likelihood of achieving a pCR.<sup>2</sup></>
        </div>
      </>
    }
  ],

  slideModalData: [
    {
      content: <>
        <div className='top-container'>
              <div className='x-container'>
              </div>
              <div className='first-text-item'>
              What is stage ll and lllA MIBC?
              </div>
          </div>
          
          <div className='numBox'>
        
              <div className='content-container'>
              AJCC: Staging Categories and Corresponding Growth of Bladder Cancer Into the Muscle and Surrounding Tissue<sup>7</sup></div>
            </div>
            <MobileOnly>
            <img style={{width: '100%'}} src={chartMobile} alt="A Table Listing Staging Categories (Stage II and Stage IIIA MIBC) and Explaining in Detail the Corresponding Growth of Bladder Cancer Into the Muscle and Surrounding Tissue" />
          </MobileOnly>
          <DesktopOnly>
            <img style={{zIndex: '9'}} src={chartDesk} alt="A Table Listing Staging Categories (Stage II and Stage IIIA MIBC) and Explaining in Detail the Corresponding Growth of Bladder Cancer Into the Muscle and Surrounding Tissue" />
          </DesktopOnly> 
           <Footnote className={"text-white text-left"}>AJCC = American Joint Committee on Cancer.</Footnote>
          </>
    },
    {
      content: <>
       <div className='top-container'>
                <div className='x-container'>
                  {/* <X onClick={handleThirdBoxClickClose}/> */}
                </div>
                <div className='first-text-item'>
                  What is high-risk breast <br className='hide-desktop'/>cancer?
                </div>
              </div>

      <div className='content-without-sideNumber'>
        <strong>Early stage breast cancer patients who have a high risk of distant disease recurrence and death despite use of optimal modern local and systemic adjuvant therapy.<sup>1</sup></strong>
      </div>
      <div className='no-pad-references'>
        <strong>Reference: 1.</strong> US Department of Health and Human Services, Food and Drug Administration, Center for Drug Evaluation and Research (CDER). Guidance for industry. Pathological complete response in neoadjuvant treatment of high-risk early-stage breast cancer: use as an endpoint to support accelerated approval. www.fda.gov/downloads/drugs/guidances/ucm305501.pdf. Published October 2014. Accessed November 26, 2019.
      </div>
      </>
    },
  ],

  iconWithTextData: [
    {
      text: <>Surgical <br/> intervention</>,
      image: SurgicalIntervention
    },
    {
      text: <>Neoadjuvant <br/> therapy</>,
      image: NeoadjuvantTherapy
    },
    {
      text: <>Patient <br/> preference</>,
      image: PatientPreference
    },
    {
      text: <>Lifestyle <br/> considerations</>,
      image: LifestyleConsiderations
    },
    {
      text: <>Potential risks <br/> and benefits</>,
      image: PotentialRiskandBenefits
    },
    {
      text: <>Patient comorbidity and tumor characteristics</>,
      image: TumorChar
    },

  ]

};


export default homeCopy;
