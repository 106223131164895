import { respondTo } from './breakpoints';
import React from 'react';
import styled from 'styled-components';

const JumpZone = styled.div`
position: absolute;
height: 0px;
display: block;

//the top value is the total height of sticky Header
top:${ ({offMobile})=> offMobile? `${offMobile}px` : '-140px'};
  ${respondTo.sm`
top:${ ({offDesktop})=> offDesktop? `${offDesktop}px` : '-60px'};
`}
`;

export default function AnchorZone({id, offDesktop, offMobile}){
  return(
    <JumpZone id={id} offMobile={offMobile}  offDesktop={offDesktop}> </JumpZone>
  )
}
