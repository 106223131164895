import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { grey } from '../../../styles/shared/colors';


export const PageSectionEl = styled.section`
  margin-left: ${({usePurple}) => (usePurple) ? '0' : 'auto'};
  margin-right: ${({usePurple}) => (usePurple) ? '0' : 'auto'};
  padding: 0 30px;
  ${({useGrey}) => (useGrey) && ' background-color: white'};
  ${({usePurple}) => (usePurple) && ' background-color: #efeefd'};
  overflow: hidden;


  

  ${respondTo.md`
    max-width: ${({usePurple}) => (usePurple) ? '100%' : '992px'};
    width: ${({usePurple}) => (usePurple) ? '100%' : 'inital'};
    padding-left: 20px;
    padding-right: 20px;
  `}
`;
