import React from 'react';
import * as s from './styles';

export default function Index({ 
    boxOneHeader,
    boxOneBody,
    boxThreeHeader,
    boxThreeBody,
    boxFourHeader,
    boxFourBody
}) {

  return (
    <s.Wrapper>
        <div className='header'>What are select recognized end points in oncology trials in MIBC?<sup>10&ndash;14</sup></div>
        <s.Box className='bot-box border-top'>
            <div className='headline'>
                {boxOneHeader}
            </div>
            {boxOneBody}
        </s.Box>
        <s.Box className='bot-box border-top '>
            <div className='headline'>
                {boxThreeHeader}
            </div>
            {boxThreeBody}
        </s.Box>
        <s.Box className='bot-box border-top'>
            <div className='headline'>
                {boxFourHeader}
            </div>
            {boxFourBody}
        </s.Box>
    </s.Wrapper>
  )
}
