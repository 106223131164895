import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { altBlue } from "../../../styles/shared/colors";

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 56px;
    font-size: 18px;
    line-height: 21px;


    ${respondTo.sm`
        font-size: 15px;
        line-height: 17px;
    `}

    .text-left {
    padding-top: 17px;
        text-align: left;
        margin: 0;
        ${respondTo.sm`
    padding-top: 25px;
        `}
    }

    .hide-desktop {
        display: block;
        ${respondTo.sm`
            display: none;
        `}
    }

    .box-popup {
        padding: 28px 35px 28px 42px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 56px;
        width: 110%;


        ${respondTo.sm`
        width: calc(48% + 110px);
        align-self:flex-end;
        margin-right: -110px;
        padding: 2em 110px 2em 38px;
    `}

        ${respondTo.lg`
            width: calc(46% + 110px);
        `}
        &:after {
            content: "";
            display: none;
            width: 100%;
            height: 100%;
            transform: translateX(100%);
            background-color: #3cbeb2;
            position: absolute;
            /* left: 200px; */
            top: 0;
            right: 0;

            ${respondTo.sm`
                display: none;
            `}
        }
    }
`;

export const Box = styled.div`
    color: white;
    background-color: ${altBlue};
    

`;

export const FirstBox = styled(Box)`
    font-size: 20px;
    line-height: 1.85714rem;
    position: relative;
    margin: 0 0 0 15px;
    padding: 25px 33px 25px 24px;
    margin-left: 0;

    sup {
      color: white;
    }

    ${respondTo.sm`
        margin-right: -110px;
        padding: 1em 110px 1em 38px;
    `}

    ${respondTo.lg`
        padding: 1em 8vw 1em 38px;
    `}
   
    &:after {
        content: "";
        display: none;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        background-color: ${altBlue};
        position: absolute;
        /* left: 200px; */
        top: 0;
        right: 0;
        

        ${respondTo.sm`
            display: none;
            margin: 2.5rem 0 8rem 0;
            padding: .8em 45px;
            margin-top: 0;
            padding-bottom: 0;
            padding-top: 0;
        `}
    }
    ${respondTo.sm`
        font-size: 1.75rem;
        line-height: 2rem;
    `}
`;

export const CaretText = styled.div`
    margin-left: -5px;
    font-weight: 700;
`;

export const Modal = styled(Box)`
    padding: 17px 25px;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    background-color: #3CBEB2;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.45);

    ${respondTo.sm`
        padding: 25px 50px;
        top: 600px;
      
    `}

    .top-container {
        display: flex;
        flex-direction: column;

        ${respondTo.sm`
            flex-direction: row;
        `}
    }

    .first-text-item {
        font-weight: 700;
        font-size: 20px;
      padding-bottom: 17px;

        ${respondTo.sm`
        padding-bottom: 25px;
        `}
    }

    .numBox {
        display: flex;
        padding-bottom: 17px;

        ${respondTo.sm`
        padding-bottom:  25px;
        `}
    }

    .content-container {
        font-size: 14px;
        sup {
          color: white;
        }
    }

    .content-without-sideNumber {
        margin-bottom: 20px;

        ${respondTo.sm`
            margin-bottom: 30px;
        `}
    }

    .references-container {
        font-size: 12px;
        font-weight: 400;
        padding-top: 0;

        ${respondTo.sm`
            padding-top: 70px;
        `}
    }
`;

export const SmallBox = styled.div`
`;
