import React, { useState } from 'react';
import { Footnote } from '../TwoColInfoBlock/styles';
import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import lifestyle_icon from "../../../assets/images/lifestyle_icon.svg";
import benefits_icon from "../../../assets/images/benefits_icon.svg";
import risk_icon from "../../../assets/images/risk_icon.svg";
import { teal } from '../../../styles/shared/colors';

const TopicsWrap = styled.div`



  .hide-desktop {
    display: block;
    ${respondTo.sm`  
      display: none;
    `}
  }

`;
const TopicsHeader = styled.h3`
  color: #4C4C4C;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 14px 15px 25px 15px;
  text-align: center;

  ${respondTo.sm`  
    padding: 30px 0 25px 0;
    font-size: 26.667px;
  `}

  sup {
    color: ${teal};
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    ${respondTo.sm`
      font-size: 15px;
    `}

  }
`;

const TopicsFooter = styled.h3`
  color: #4C4C4C;
  font-family: Arial;
  font-size: 23.333px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 17px 0;

  ${respondTo.sm`
    padding: 25px 0;
  `}

`;

const TopicsFootnote = styled.p`
  color: #000;
  font-family: Arial;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-bottom: 0 !important;
    padding: 17px 0;

  ${respondTo.sm`
    padding: 0px;
    padding: 25px 0;
  `}
`;

const TopicsContentWrap = styled.div`
  ${respondTo.sm`
    padding-left: 85px;
  `}
`;

const TopTopicsContentWrap = styled(TopicsContentWrap)`
  border-bottom: 1px solid black;
`;

const IconBlockTray = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 17px;

  ${respondTo.sm`
    flex-direction: row;
    gap: 0;
    padding-bottom: 25px;
  `}

  
`;

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-right: auto;
  gap: 17px;

  ${respondTo.sm`
    flex-direction: column;
    gap: 25px;
    width: 33.3%;
  `}

  img {
    max-height: 100%;
    max-width: 77px;
    
    ${respondTo.sm`
      max-width: 92px;
      max-height: 92px;
      padding-left: 0;
    `}
  }

  p {
    color: #4C4C4C;
    text-align: left;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.333px;
    margin-bottom: 0;

    ${respondTo.sm`
      text-align: center;
    `}
  }


  br {
    display: none;
    ${respondTo.sm`
      display: block;
    `}
  }

`;


const TopicsContent = [
  {imgPath: risk_icon, content: <>Risks and benefits <br/>of surgery</>},
  {imgPath: lifestyle_icon, content:  <>Lifestyle changes <br/>associated with surgery</>},
  {imgPath: benefits_icon, content: <>Potential benefits<br/> of cisplatin-based neoadjuvant therapy</>},
]
const Topics = ({ recurranceImg, GradientBlockContent}) => {
  

  return (
    <TopicsWrap>
    <TopTopicsContentWrap>
      <TopicsHeader>These topics should be discussed with your appropriate patients with MIBC<sup>8</sup>:</TopicsHeader>
      <IconBlockTray>
        {TopicsContent.map(TopicContent => {
          return (<> <IconBlock>
          <img src={TopicContent.imgPath} />
          <p>{TopicContent.content}</p>
        </IconBlock></>);
        })}
      </IconBlockTray>
    </TopTopicsContentWrap>
    <TopicsContentWrap>
      <TopicsFooter>Identify if your patients with MIBC are appropriate for cisplatin-based neoadjuvant therapy.</TopicsFooter>
    </TopicsContentWrap>
  </TopicsWrap>
  )
}


export default Topics;
