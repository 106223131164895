import React from 'react';
import * as s from './styles';


export default function Index({
  title,
  heroImg,
  middleText,
}) {
  return (
    <s.Root>
      <div className='title'>
        {title}
      </div>
      <div className='image-container'>
        <img 
          src={heroImg}
          alt=''
        />
      </div>
      <div className='middle-text'>
        {middleText}
      </div>
    </s.Root>
  )
}
